.shop-card-container {
    display: flex;
    flex-direction: column;
    width: 435px;
    height: auto;
    /*background: red;*/
    gap: 20px;
    color: white;
    position: relative;
    z-index: 99;
    flex-shrink: 0;
}

.shop-card-cover {
    width: 325px;
    height: 325px;
}

.shop-card-cover img {
    cursor: pointer;
    max-width: 100%;
    object-fit: cover;
}

.shop-card-buy-container {
    width: 100%;
    z-index: 99;
}

.shop-card-buy {
    position: relative;
    border-radius: 11px;
    border: 1px solid gray;
    color: white;
    padding: 10px;
    display: flex;
    width: 100px;
    cursor: pointer;
}

.shop-card-buy:hover {
    color: red;
}