.shop-container {
    width: 100%;
    color: white;
    font-family: Uavmono;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
}

.shop-wrapper {
    width: 80%;
    display: flex;
    overflow: hidden;
    gap: 30px;
}
