.event-card-container {
    display: flex;
    flex-direction: column;
    width: 435px;
    height: auto;
    /*background: red;*/
    gap: 20px;
    color: white;
    position: relative;
    z-index: 99;
    flex-shrink: 0;
}

.event-card-cover {
    border: 1px gray solid;
}

.event-card-cover img {
    max-width: 100%;
    object-fit: cover;
}

.event-card-ticket-container {
    width: 100%;
    z-index: 99;
}

.event-card-ticket {
    position: relative;
    border-radius: 11px;
    border: 1px solid gray;
    color: white;
    padding: 10px;
    display: flex;
    width: 100px;
    cursor: pointer;
}

.event-card-ticket:hover {
    color: red;
}

.event-card-detail-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 5px;
    min-height: 50px;
    align-items: baseline;
}

.event-card-detail-date, .event-card-detail-party  {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.event-card-detail-date-month {
    font-size: 18px;
}

.event-card-detail-party-clock {
    font-size: 24px;
}

.event-card-detail-date-day {
    font-size: 28px;
}

.event-card-detail-party-clock, .event-card-detail-party-what {
    font-family: Opensans;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.event-card-detail-party-clock, .event-card-detail-party-what, .event-card-detail-date-month, .event-card-detail-date-day {
    height: 25px;
}
