.header-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 5;
    margin-bottom: 70vh;
}

.header-wrapper {
    width: 80%;
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid gray;
}

.header-desktop-menu {
    color: white;
    display: flex;
    align-items: center;
    gap: 20px;
}

.header-desktop-menu img {
    height: 25px;
}

.desktop-menu-elem {
    text-transform: uppercase;
    font-size: 18px;
    height: 25px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Uavmono;
    animation-play-state: paused;
    cursor: pointer;
}

.desktop-menu-elem:hover {
    animation: blink .3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.desktop-menu-elem:first-child {
    padding: 0;
}

.desktop-menu-elem:first-child:hover{
    animation-play-state: paused;
    transform: rotate(-15deg);
}

.desktop-menu-elem:last-child:hover {
    animation-play-state: paused;
    color: red;
    transform: scale(1.1);
    /*animation: blink 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;*/
}

.ticket {
    border-radius: 11px;
    border: 1px solid gray;
}

.header-mobile-menu {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mobile-menu-icon {
    color: white;
    font-size: 36px;
    cursor: pointer;
}

.mobile-menu-icon:hover {
    transform: skew(15deg, 15deg);
}

@keyframes blink {

    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}