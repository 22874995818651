.title-container {
    width: 100%;
    color: white;
    font-family: Uavmono;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    margin: 50px 0;
}

.title-wrapper {
    width: 80%;
    display: flex;
}


.title {
    letter-spacing: 1.5px;
    font-size: 32px;
}