.line-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 90px 0px 30px 0px;

}

.line {
    width: 80%;
    height: 1px;
    background: #444;
}