.footer {
    width: 100%;
    background: #121212;
    height: 200px;
    flex-direction: column;
    gap: 30px;
    color: #3A3A3A;
    font-size: 12px;
}

.footer a {
    text-decoration: none;
    color: #3A3A3A;
}

.footer-documents-container {
    flex-direction: column;
}

.footer-documents {
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}


.flex {
    display: flex;
}

.center-flex {
    align-content: center;
    align-items: center;
    justify-content: center;
}