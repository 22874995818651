.logobg {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 1;
    background: url('../img/bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    /*background-size: contain;*/
    animation: movebg 15s infinite;
}




@keyframes movebg {
    50% {
        background-position: -10px top;
    }
}