@font-face {
  font-family: MontBold;
  src: url("../fonts/Montserrat-Bold.otf") format("opentype");
}

@font-face {
  font-family: MontSem;
  src: url("../fonts/Montserrat-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: MontReg;
  src: url("../fonts/Montserrat-Regular.otf") format("opentype");
}

@font-face {
  font-family: MontLight;
  src: url("../fonts/Montserrat-Light.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../fonts/Montserrat-Medium.otf") format("opentype");
}

@font-face {
  font-family: RobMed;
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Uavmono;
  src: url("../fonts/UAV-OSD-Mono.ttf") format("truetype");
}

@font-face {
  font-family: Uavsans;
  src: url("../fonts/UAV-OSD-Sans-Mono.ttf") format("truetype");
}

@font-face {
  font-family: Opensans;
  src: url("../fonts/OpenSans-Variable.ttf") format("truetype");
}



.montbold {
  font-family: MontBold;
}

.montreg {
  font-family: MontReg;
}

.montlight {
  font-family: MontLight;
}

.montsem {
  font-family: MontSem;
}

.roboto {
  font-family: Roboto;
}

.roboto-medium {
  font-family: RobMed;
}

.uav-mono {
  font-family: Uavmono;
}

.uav-sans {
  font-family: Uavsans;
}