.event-container {
    width: 100%;
    color: white;
    font-family: Uavmono;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
}

.event-wrapper {
    width: 80%;
    display: flex;
    overflow: hidden;
    gap: 30px;
}

.arrow-cont {
    width: 5%;
    height: 225px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.arrow-right, .arrow-left {
    width: 10px;
    height: 10px;
    border-top: 2px solid white;
    cursor: pointer;
    transition: .5s;
}

.arrow-right:hover, .arrow-left:hover {
    border-color: red;
}

.arrow-right {
    border-right: 2px solid white;
    transform: rotate(45deg);
}

.arrow-left {
    border-left: 2px solid white;
    transform: rotate(-45deg);
}